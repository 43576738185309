<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <div>
        <div class="flex jc-b">
      <el-button type="warning" @click="add()" plain>新增活动</el-button>
        <el-select  v-model="ac_status" placeholder="" style="width:100px;" @change="typeChange">
            <el-option  label="上架" value="2"></el-option>
            <el-option  label="下架" value="1"></el-option>
        </el-select>
    </div>
        <el-table  :data="dataList" style="width: 100%" highlight-current-row>
            <el-table-column prop="id" align="center" label="id"></el-table-column>
            <el-table-column prop="name" align="center" label="活动类型" ></el-table-column>
            <el-table-column prop="descr" align="center" label="活动标题" ></el-table-column>
            <el-table-column prop="number_of_people" align="center" label="成团人数" ></el-table-column>
            <el-table-column prop="start_time" align="center" label="活动开始时间" ></el-table-column>
            <el-table-column :formatter="fromTitle" prop="end_time" align="center" label="活动结束时间" width="200">
            </el-table-column>
            <el-table-column prop="is_recommend" align="center" label="机构管理">
              <template slot-scope="scope">
                <div class="flex-clo al-c">
                  <el-button @click="addCourse(scope.row)" type="text" size="small">添加课程</el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="is_recommend" align="center" label="活动操作">
              <template slot-scope="scope">
                <div class="flex-clo al-c">
                  <el-button @click="toDetails(scope.row)" type="text" size="small">查看详情</el-button>
                  <el-button type="text" size="small" @click="update(scope.row)">修改</el-button>
                  <el-button type="text" size="small" v-if="scope.row.status == 1" @click="updateStatus(scope.row,2)">上架</el-button>
                  <el-button type="text" size="small" v-if="scope.row.status == 2" @click="updateStatus(scope.row,1)">下架</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        <!--分页-->
        <el-pagination background @current-change="GetList"
          :current-page.sync="currentPage" :page-size="10" layout="prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
    <!--  -->

    </div>
</template>

<script>
export default {

    data() {
        return {
            currentPage:1,
            dataList:[],
            total:0,
            ac_status:'2'
        };
    },

    mounted() {
        this.GetList()
    },

    methods: {
        toDetails(row) {
      this.$router.push({
        name: "EventDetails",
        query: { id: row.id }
      });
    }, 
        updateStatus(row,status){
      let url = "/user/businessActivityType/update";
      let data={
        id: row.id,
        status
      }

 this.$axios.post(url, data).then((res) => {
        this.$message({ type: 'info', message: res.data.message })
          this.GetList();
      });
    },

    addCourse(val) {
      this.$router.push({
        name: "Activityaction",
        query: { id: val.id },
      });
    },
        //修改
    update(row) {
      this.$router.push({ name: "addActivity",query:{aid:row.id,type: 'grouping'} });
    },
     //新增活动
        add() {
      this.$router.push({ name: "addActivity",query:{type: 'grouping'} });
    },
    typeChange(){
        this.GetList()
    },
    //活动列表
        GetList() {
      this.$axios({
        method: "get",
        url:"/user/businessActivityType/queryManagerListPage",
        params: {
          pageSize: 10,
          currentPage: this.currentPage,
          status:this.ac_status,
          type:'grouping'
        },
      })
        .then((res) => {
          this.dataList = res.data.data.rows;
          this.total = res.data.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    },
};
</script>

<style lang="less" scoped>
</style>